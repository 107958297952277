import { About } from "./components/About";
import { Cta } from "./components/Cta";
import { FAQ } from "./components/FAQ";
import { Features } from "./components/Features";
import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { HowItWorks } from "./components/HowItWorks";
import { Navbar } from "./components/Navbar";
import { Newsletter } from "./components/Newsletter";
import { Pricing } from "./components/Pricing";
import { ScrollToTop } from "./components/ScrollToTop";
import { Services } from "./components/Services";
import { Sponsors } from "./components/Sponsors";
import { Team } from "./components/Team";
import { Testimonials } from "./components/Testimonials";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useParams,
} from "react-router-dom";

import "./App.css";
import { CookiesPolicy } from "./components/CookiesPolicy";
import { CookiesDisclaimer } from "./components/CookiesDisclaimer";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const FullScreenWrapper = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Outlet />
    </div>
  );
};

const LangWrapper = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return <Outlet />;
};

const Landing = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      {/* <Sponsors /> */}
      {/* <About /> */}
      {/* <Features /> */}
      <HowItWorks />
      {/* <Services /> */}
      {/* <Cta /> */}
      {/* <Testimonials /> */}
      {/* <Team /> */}
      {/* <Pricing /> */}
      {/* <Newsletter /> */}
      {/* <FAQ /> */}
      {/* <Footer /> */}
      <ScrollToTop />
    </div>
  );
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/:lang/",
      element: <FullScreenWrapper />,
      children: [
        {
          path: "/:lang/",
          element: <LangWrapper />,
          children: [
            { path: "/:lang/", element: <Landing /> },
            { path: "/:lang/politica-de-cookies", element: <CookiesPolicy /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <FullScreenWrapper />,
      children: [
        {
          path: "/",
          element: <LangWrapper />,
          children: [
            { path: "/", element: <Landing /> },
            { path: "/politica-de-cookies", element: <CookiesPolicy /> },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
